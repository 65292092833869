// Imports fonts
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

// Defines global styling
$font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
$defaultBlack: #222;
$defaultWhite: #FAFAFA;

// Imports bootstrap
@import "node_modules/bootstrap/scss/bootstrap";

// GENERAL STYLE
body{
    font-family:$font-family !important;
    --font-family-sans-serif:$font-family !important;
    font-weight: 400 !important;
    color: $defaultBlack !important;
}

h1, h2, h3, h4, h5, h6{
    font-weight: 700;    
    letter-spacing: -2px;
}

// HELPERS
.bold{
    font-weight: 700;
}

.blackBg{
    background-color: $defaultBlack;
    color: $defaultWhite;
}

.jobTitle{
    font-size: 1.5rem;
    letter-spacing: -1.5px;
}
.grecaptcha-badge {
    display:none !important;
}
.grey-bg{
    background-color:#444;
    color: $defaultWhite;
}

a{
    text-decoration: none;
    color: $defaultBlack;
}